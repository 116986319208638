import React from 'react';

import ConsultantLayout from '../../layout/ConsultantLayout';
import AccountManagementView from '../../views/consultant/AccountManagementView';

const ConsultantPage = (): React.ReactElement => {
  return (
    <>
      <ConsultantLayout>
        <AccountManagementView />
      </ConsultantLayout>
    </>
  );
};

export default ConsultantPage;
