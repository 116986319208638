import React, { ReactElement } from 'react';
import { Card, createStyles, Divider, WithStyles, withStyles } from '@material-ui/core';

import PersonalAccountNavigation from '../../components/PersonalAccountNavigation';

const styles = createStyles({
  root: {
    width: '1286px',
    display: 'flex',
    margin: '0 auto',
    padding: '0',
    height: '100vh',
  },
  left: {
    padding: '2rem 0',
    width: '256px',
  },
  right: {
    flex: '1',
    padding: '2rem',
  },
  rightCard: {
    padding: '2rem',
  },
  textContent: {
    margin: '1rem 0',
    whiteSpace: 'normal',
  },
});

const AccountManagementView = ({ classes }: Props): ReactElement => {
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <PersonalAccountNavigation> </PersonalAccountNavigation>
      </div>
      <div className={classes.right}>
        <Card variant="outlined" className={classes.rightCard}>
          <h3>账号管理</h3>
          <div className={classes.textContent}>
            <h4>姓名</h4>
          </div>
          <Divider />
          <div className={classes.textContent}>
            <h4>绑定手机号</h4>
            修改手机号请前往“我的-设置-账号与安全中心-账号管理”进行修改 手机号183******31
          </div>
          <Divider />
          <div className={classes.textContent}>
            <h4>邮箱</h4>
          </div>
          <Divider />
          <div className={classes.textContent}>
            <h4> 修改密码</h4>
          </div>
          <Divider />
          <div className={classes.textContent}>注销账号</div>
        </Card>
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(AccountManagementView);
